const titles = {
    "error_upload_editor":          "Error al crear/editar el registro",
    "error_generico":               "Error",
    "error_login":                  "Error al iniciar sesión",
    "error_download":               "Error al descargar el archivo",
    "error_zone_collide":           "Error en la georeferenciación",
    "success_generico":             "Acción realizada",
    "question_cancel_new":          "Cancelar creación",
    "question_cancel_edit":         "Cancelar edición",
    "question_tarea_estado":        "Cambiar estado de tarea",
    "question_eliminar_capa":       "Eliminar capa",
    "question_eliminar_file":       "Eliminar archivo",
    "question_eliminar_kmz":        "Eliminar archivo KMZ",
    "question_activate_user":       "Activar usuario",
    "question_deactivate_user":     "Desactivar usuario",
    "question_delete_user":         "Eliminar usuario",
    "question_delete_item":         "Eliminar registro",
    "question_delete_curso":        "Eliminar curso",
    "question_delete_eval":         "Eliminar evaluación",
    "question_delete_group":        "Eliminar grupo de acceso"
}

const texts = {
    "error_autorizacion":           "No se pudo validar su sesión, refresque la página e intentelo nuevamente.",
    "error_session_expired":        "Su sesión ha expirado, y no puede realizar esta acción. Por favor, vuelva a iniciar sesión.",
    "error_access_changed":         "No se ha podido realizar los cambios a los permisos de este usuario. Por favor, intente nuevamente más tarde.",
    "error_desconocido":            "Ocurrió un problema al procesar su solicitud. Por favor, intente nuevamente más tarde.",
    "error_mapa_desconocido":       "Ocurrió un problema al cargar los datos de visualización de mapa. Intente nuevamente más tarde.",
    "error_detalles":               "Ocurrió un problema al procesar su solicitud:",
    "error_download":               "Ocurrió un problema al obtener el archivo para su descarga. Intentelo nuevamente más tarde.",
    "error_login_blank_1":          "El nombre de usuario no puede quedar en blanco.",
    "error_login_blank_2":          "Ingrese una contraseña.",
    "error_login_common":           "Las credenciales ingresadas no coinciden con ningúna cuenta activa.",
    "error_check_fields":           "Por favor complete los campos solicitados.",
    "error_upload":                 "Se ha producido un error al subir el registro.",
    "error_propio_jefe":            "No se admite la autoasignación del titular de Jefe.",
    "error_lv_cero_tareas":         "Los registros de liderazgo requieren al menos una tarea. Por favor, inserte una.",
    "error_tarea_cambio_estado":    "Hubo un error al cambiar el estado de esta tarea.",
    "error_zone_collide":           "La zona que acaba de dibujar colisiona con otra ya existente.",
    "error_area_vacia":             "Debe dibujar un área, ya sea desde un polígono, hasta un marcador.",
    "error_incidente_tarea_one":    "El registro de incidente ambiental debe conservar al menos una tarea.",
    "error_incidente_duracion":     "El incidente ambiental debe durar al menos 5 días.",
    "error_permiso_cero_tarea":     "Un permiso crítico debe conservar al menos una tarea.",
    "error_usuario_pass":           "No ha sido posible cambiar la contraseña.",
    "error_eliminar_pres":          "No ha sido posible eliminar la presentación. Asegurese que este archivo no esté siendo usado por ninguna evaluación.",
    "zone_evaluator_error":         "Su zona colisiona con otras areas, ya sean porque han sido areas previamente solicitadas o porque son areas donde se han presentado incidentes. Pruebe con una zona distinta.",
    "zone_evaluator_success":       "La zona seleccionada es apta para poder realizar intervenciones",
    "success_upload_new":           "Se ha subido exitosamente el registro creado.",
    "success_upload_edit":          "Se ha editado exitosamente el registro.",
    "success_new_empleado":         "Se ha añadido un nuevo empleado.",
    "success_new_usuario":          "¡Se ha creado exitosamente un nuevo usuario!",
    "success_editar_usuario":       "<b>Se han guardado los cambios para el usuario.</b><br/>Si el usuario está activo en estos momentos, sus cambios serán visibles la próxima vez que vuelva a iniciar sesión.",
    "success_cambios_alertas":      "Se han guardado todos los cambios en la configuración.<br/>Dependiendo de cada configuración, sus cambios se verán visibles en diferentes intervalos horarios:<br/><ul><li>Tareas críticas, Compromisos ambientales, Compromisos de seguridad y SIAs: cambios cada 6 horas</li></ul>",
    "success_usuario_pass":         "¡La contraseña ha sido cambiada con éxito!.",
    "success_access_changed":       "Se ha efectuado con éxito los cambios de permiso del usuario seleccionado.",
    "question_delete_group":        "¿Está seguro que desea eliminar este grupo de acceso?<br/>Los cursos, participantes y evaluadores registrados se mantendrán.",
    "question_cancel_edit":         "¿Está seguro que desea cancelar la edición del registro?",
    "question_cancel_new":          "¿Está seguro que desea cancelar la creación del nuevo registro?",
    "question_tarea_cerrar":        "¿Está seguro que desea cerrar esta tarea?",
    "question_tarea_invalidar":     "¿Está seguro que desea invalidar esta tarea?",
    "question_eliminar_curso":      "¿Está seguro que desea eliminar este curso?<br/>Todas sus evaluaciones también serán eliminadas, pero el material de apoyo se mantendrá.",
    "question_eliminar_capa":       "¿Está seguro que desea eliminar esta capa para los mapas?",
    "question_eliminar_kmz":        "¿Está seguro que desea eliminar el archivo KMZ?<br/><b>Esta acción es irreversible, y el archivo será eliminado del servidor.</b>",
    "question_eliminar_file":       "¿Está seguro que desea eliminar este archivo?",
    "question_eliminar_pres":       "¿Está seguro que desea eliminar este material de apoyo?<br/><br/><b>El archivo será completamente eliminado del servidor y desvinculado de cualquier evaluación en el que esté siendo usado. Esta acción es irreversible.</b>",
    "question_eliminar_eval":       "¿Está seguro que desea eliminar la evaluación?<br/>Se eliminarán las preguntas, pero se conservará el material de apoyo y las respuestas generadas por los usuarios.",
    "question_activate_user":       "¿Está seguro que desea volver a habilitar el acceso a plataforma a este usuario?",
    "question_deactivate_user":     "¿Está seguro que desea deshabilitar el acceso a plataforma a este usuario?",
    "question_delete_user":         "<b>¿Está seguro que desea eliminar a este usuario?</b><br/><br/>Todos los registros y tareas inscritas con el perfil de empleado de este usuario se mantendrán.",
    "delete_item":                  "¿Está seguro/a que desea eliminar este registro?<br/><b>Esta acción es irreversible, el registro será irrecuperable.</b>",
    "success_editar_curso_externo": "El participante ha sido actualizado con sus respectivos cursos externos",

}


export const messages = {
    titles,
    texts
}