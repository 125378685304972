import Vue from 'vue'
import store from '@/store'
import VueRouter from 'vue-router'
import Login      from '@/dash/views/Login.vue'
import Logout     from '@/dash/views/Logout.vue'
import Dashboard  from '@/dash/views/Dashboard.vue'
import Page404 from '@/shared/views/page_404.vue'

import { routes_lv } from './routes.lv'
import { routes_incidentes } from './routes.incidente'
import { routes_sias } from './routes.sia'
import { routes_users } from './routes.config'
import { routes_pc } from './routes.pc'
import { routes_meteodata } from './routes.meteo'
import { axiosHelper } from '@/helpers'

Vue.use(VueRouter)

const routes = [
  { 
    path: "*", 
    component: Page404 
  },
  { 
    path: '', 
    redirect: { name: 'login' } 
  },
  {
    path: '/login', 
    name: 'login',
    meta: { session: true },
    component: Login 
  },
  { 
    path: '/logout',
    name: 'logout', 
    component: Logout 
  },
  {
    path: '/dashboard',
    // name: 'dashboard',
    meta: { auth: true },
    component: Dashboard,
    children: [
      {
        path: '', name: 'dashboard_index',
        component: () => import('@/dash/views/dashboard/index.vue')
      },
      // ------- EVALUADOR DE AREAS
      {
        path: 'evaluador', name: 'dashboard_evaluador',
        meta: { module: "mod_sia" },
        component: () => import('@/dash/views/dashboard/index_evaluador.vue')
      },
      // ------- EMPLEADOS
      {
        path: 'empleados', name: 'dashboard_empleados',
        meta: {
          module: "mod_empleados",
          groups: ['supers', 'administradores']
        },
        component: () => import('@/dash/views/dashboard/index_empleados.vue')
      },
      ...routes_users,
      // ------- SIAS
      ...routes_sias,
      // ------- INCIDENTES
      ...routes_incidentes,
      // ------- LIDERAZGOS
      ...routes_lv,
      // ------- PERMISOS
      ...routes_pc,
      // ------- METEODATA
      ...routes_meteodata,
      // ------- Reportes diarios
      {
        path: 'reportesdiarios', name: 'dashboard_dailyreports',
        meta: {
          module: "mod_reportes",
          groups: ['supers', 'administradores'] 
        },
        component: () => import('@/dash/views/dashboard/index_daily.vue')
      },
      // ------- KMZ
      {
        path: 'kmz', name: 'dashboard_kmz',
        meta: {
          groups: ['supers', 'administradores']
        },
        component: () => import('@/dash/views/dashboard/index_kmz.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes
})

router.beforeEach(async (to, from, next) => 
{
  const token = localStorage.getItem('token');

  if (to.matched.some(record => record.meta.session)) {
    if (token) {
      next({ name: 'dashboard_index' });
      return;
    }
  }

  if(to.matched.some(record => record.meta.auth)) 
  { 
    if (!token) {
      next({ name: 'login' });
      return;
    }
    
    if(!!token) {
      // console.log("ALO ALO ALO ALO ALO ALO ALO")
      // console.log(to.meta.module)
      // await axiosHelper.post('mod/access/', { mod: to.meta.module })
      //   .then(response => store.commit('app/set_module_data', response.data))
      //   .catch(error => store.commit('app/set_module_data', null));
      // Busca el segmento de ruta que contiene la propiedad 'module' en su meta
      const routeWithModule = to.matched.find(record => record.meta.module);
      const moduleMeta = routeWithModule ? routeWithModule.meta.module : null;

      console.log("Valor de module");
      console.log(moduleMeta); // Debería mostrar el valor correcto de `module`, si existe

      if (moduleMeta) {
        await axiosHelper.post('mod/access/', { mod: moduleMeta })
          .then(response => store.commit('app/set_module_data', response.data))
          .catch(error => store.commit('app/set_module_data', null));
      } else {
        // Manejo en caso de que moduleMeta sea null o undefined
        console.log('La propiedad module no está definida para la ruta actual.');
      }
    }
    
    //En caso que no se admita el tipo de usuario de acuerdo a lo almacenado --> Dashboard Index
    const user = store.state.auth.user;
    if (!!to.meta.groups && !to.meta.groups.some(g => user.groups.includes(g))) {
      
        //console.log('Esta sección no es accesible para este tipo de usuario.');
        next({ name: 'dashboard_index' });
        return;
    }

    next();
    return;
  }
  
  next();
});

export default router;